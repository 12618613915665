<template>
    <v-dialog
        v-model="dialog"
        width="420"
        overlay-opacity="0.8"
        persistent
    >
        <v-card color="white" class="pa-5" align="center">
            <v-icon class="mt-3" color="red" size="55">
                mdi-close-circle-outline
            </v-icon>
            <p class="mt-5 text-h5 red--text font-weight-bold">{{ topic }}</p>
            <label class="d-block">{{ text }}</label>
            <v-btn 
                class="mt-5 px-9"
                color="yellow darken-3"
                rounded
                @click="hide"
            >
                ตกลง
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        topic: { type: String, default: '' },
        text: { type: String, default: '' }
    },
    data: () => ({
        dialog: false
    }),
    methods: {
        show(){
            this.dialog = true;
        },
        hide(){
            this.dialog = false;
            this.$emit('hide');
        }
    },
    mounted(){

    }
};
</script>